
import { defineComponent } from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import { agent } from "@/agent";
import { useStorage } from "@/composables/useStorage";
import { useRouter } from "vue-router";
import {
  IonToolbar,
  IonItem,
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonCard,
  IonInput,
  IonLabel,
  IonButton
} from "@ionic/vue";
import { useFeature } from "@/composables/useFeature";

export default defineComponent({
  components: {
    IonToolbar,
    IonItem,
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonCard,
    IonInput,
    IonLabel,
    IonButton
  },
  setup() {
    const { resetPin } = useStorage();
    const { raiseToast } = useFeature();
    const router = useRouter();

    // Validation
    const schema = yup.object({
      email: yup
        .string()
        .email()
        .required(),
      password: yup.string().required()
    });

    const theForm = useForm({ validationSchema: schema });

    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } = useField(
      "password"
    );

    // Methods
    const submit = async () => {
      const isValid = (await theForm.validate()).valid;
      if (!isValid) {
        return;
      }
      const { bazookaLogin } = agent();
      const res = await bazookaLogin(
        email.value as string,
        password.value as string
      );
      if (res && res.status === 200) {
        await resetPin();
        await router.push({ name: "Pin" });
        await theForm.resetForm();
      } else {
        await raiseToast(
          "There was a problem logging you in. Please try again",
          "danger"
        );
      }
    };
    return {
      email,
      password,
      emailError,
      passwordError,
      submit
    };
  }
});
